import { Flex, Container } from '@chakra-ui/react'
import { GetServerSidePropsContext } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import { ProjectsAPI } from '@miimosa/api/v3'
import { CountryCode, FrontSearchProjectType, v3toFrontSearchProject } from '@miimosa/common'
import { parseLocale } from '@miimosa/design-system/lib/hooks/use_platform'
import { Testimonial, testimonials } from '@miimosa/directus'
import { Search } from '@miimosa/protocol'

import {
  Commitments,
  TestimonialStories,
  Impacts,
  Engagements,
  History,
  Hero,
  NewProjects,
} from '@components/pages/home'

const getTestimonialsFromDirectus = async (): Promise<Testimonial[]> => {
  try {
    const {
      data: { data },
    } = await testimonials({ displayHome: true })
    return data
  } catch (err) {
    return []
  }
}

const safeGetFeaturedProjects: (params: Search.FeaturedProjectsRequest) => Promise<FrontSearchProjectType[]> = async (
  params
) => {
  const projectsApi = new ProjectsAPI()
  try {
    let formattedProjects: FrontSearchProjectType[] = []
    const results = await projectsApi.getFeaturedProjects(params)
    if (results.kind === 'success') {
      formattedProjects = results.data.projects.map((project) =>
        v3toFrontSearchProject({ ...project, imageFileSize: 'card' })
      )
      return formattedProjects
    }
    return []
  } catch {
    return []
  }
}

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  const { locale } = context
  const { lang, platform } = parseLocale(locale)

  return {
    props: {
      ...(await serverSideTranslations(locale || 'fr', ['common', 'home'])),
      testimonials: await getTestimonialsFromDirectus(),
      projects: await safeGetFeaturedProjects({
        branch_code: `mii_be`,
        locale: lang,
      }),
      platform,
    },
  }
}

const Home = ({
  testimonials,
  projects,
  platform,
}: {
  testimonials: Testimonial[]
  projects: FrontSearchProjectType[]
  platform: CountryCode
}) => {
  return (
    <Flex direction="column" w="full">
      <Hero />
      <Flex w="full" bg="creamy" direction="column" zIndex={1}>
        <Container variant="boxed" w="full" flexDir="column" alignItems="center">
          <History />
        </Container>
      </Flex>
      <Flex w="full" bg="white" direction="column">
        <Engagements />
      </Flex>
      {testimonials && <TestimonialStories testimonials={testimonials} />}
      {platform == 'be' && <NewProjects projects={projects} px={{ base: 10, md: '60px' }} />}
      <Commitments />
      <Impacts />
    </Flex>
  )
}

export default Home
